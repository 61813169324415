import service from "@/api/service";
import { PointProfileGetRequest } from "@/api/analysis/point-profile/request";
import { PointProfileGetResponse } from "@/api/analysis/point-profile/response";

/**
 * ポイント付与数・利用金額プロフィール属性情報取得APIをコールします。
 *
 * @param getRequest ポイント付与数・利用金額プロフィール属性情報取得APIのリクエストボディ
 * @return PointProfileGetResponse
 */
export async function get(getRequest: PointProfileGetRequest) {
  const response = await service.post("/analysis-point-profile", getRequest);
  return response.data as PointProfileGetResponse;
}
