import { Component, Mixins, Prop } from "vue-property-decorator";
import UlLineChart from "@/models/analysis/ul-line-chart";
import AnalysisBase, { ChartElement } from "@/models/analysis/analysis-base";
import * as PointBalanceUserCSV from "@/api/analysis/point-balance-user-csv";
import { isSuccess } from "@/api/response";
import Flash, { ErrorAlert } from "@/store/common/flash";
import PointBalanceGet from "@/store/analysis/point-balance/get";
import { PointInputOptions } from "@/models/analysis/point";
import { PointBalanceUserCSVGetRequest } from "@/api/analysis/point-balance-user-csv/request";
import { AnalysisGraphData } from "@/api/analysis/common/response";

@Component({
  components: {
    UlLineChart
  }
})
export default class AnalysisPointBalanceChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: PointInputOptions;
  @Prop() data!: AnalysisGraphData;

  get pointBalanceChartData() {
    return this.getLineChartData(this.data);
  }

  get pointBalanceChartOption() {
    return this.getLineChartOptions(this.data, true, this.onClickChart);
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async onClickChart(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }
    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchPointBalanceUserCSV(element._index);
    }
  }

  async fetchPointBalanceUserCSV(elementIndex: number) {
    if (this.inputOptions.selectShop == null) {
      return false;
    }

    const pointBalanceUserCSVGetRequest: PointBalanceUserCSVGetRequest = {
      shopId: Number(this.inputOptions.selectShop.id),
      basePoint: PointBalanceGet.getBasePoint,
      pointBand: Number(PointBalanceGet.getData.labelsData[elementIndex])
    };

    const pointBalanceUserCSVGetResponse = await PointBalanceUserCSV.get(
      pointBalanceUserCSVGetRequest
    );
    if (!isSuccess(pointBalanceUserCSVGetResponse)) {
      await Flash.setErrorNow({
        message: pointBalanceUserCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
    }
    this.saveCSV(
      pointBalanceUserCSVGetResponse.results.csvString,
      pointBalanceUserCSVGetResponse.results.fileName
    );
  }
}
