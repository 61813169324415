import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import { Line, mixins } from "vue-chartjs";
import Chart from "chart.js";

@Component
export default class UlLineChart extends Mixins(Line, mixins.reactiveProp) {
  @Prop() chartData!: Chart.ChartData;
  @Prop() chartOptions!: Chart.ChartOptions;

  @Emit()
  public chartRendered() {
    return this.$data._chart;
  }

  public mounted() {
    this.renderChart(this.chartData, this.chartOptions);
    this.chartRendered();
  }
}
