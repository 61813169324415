import service from "@/api/service";
import { PointCompleteGetRequest } from "@/api/analysis/point-complete/request";
import { PointCompleteGetResponse } from "@/api/analysis/point-complete/response";

/**
 * ポイントコンプリートチケット情報取得APIをコールします。
 *
 * @param getRequest ポイントコンプリートチケット情報取得APIのリクエストボディ
 * @return PointCompleteGetResponse
 */
export async function get(getRequest: PointCompleteGetRequest) {
  const response = await service.post("/analysis-point-complete", getRequest);
  return response.data as PointCompleteGetResponse;
}
