import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import * as PointAPI from "@/api/analysis/point";
import { isSuccess } from "@/api/response";
import { PointGetRequest } from "@/api/analysis/point/request";
import { PointGetResponse } from "@/api/analysis/point/response";
import { AnalysisGraphData } from "@/api/analysis/common/response";

const MODULE_NAME = "analysis/point/get";

/**
 *  ポイント付与数・利用金額情報取得API（/analysis-point）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getRequest: PointGetRequest = {} as PointGetRequest;
  getResponse: PointGetResponse = {} as PointGetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getData() {
    if (this.getResponse.results) {
      return this.getResponse.results.data;
    } else {
      return {} as AnalysisGraphData;
    }
  }

  get getTotalCount() {
    if (this.getResponse.results) {
      return this.getResponse.results.totalCount;
    } else {
      return 0;
    }
  }

  get getGetRequest() {
    return this.getRequest;
  }

  // MutationActions
  @MutationAction
  async get(getRequest: PointGetRequest) {
    const getResponse = await PointAPI.get(getRequest);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as PointGetResponse
    };
  }
}

export default getModule(Get);
