import { Component, Emit, Mixins, Prop, Watch } from "vue-property-decorator";
import UlBarChart from "@/models/analysis/ul-bar-chart";
import UlPieChart from "@/models/analysis/ul-pie-chart";
import Flash, { ErrorAlert } from "@/store/common/flash";
import AnalysisBase from "@/models/analysis/analysis-base";
import { ChartElement } from "@/models/analysis/analysis-base";
import { AnalysisGraphData } from "@/api/analysis/common/response";
import PointProfileGet from "@/store/analysis/point-profile/get";
import { PointInputOptions } from "@/models/analysis/point";
import { PointProfileGetRequest } from "@/api/analysis/point-profile/request";
import PointGet from "@/store/analysis/point/get";
import * as PointUserCSV from "@/api/analysis/point-user-csv";
import { isSuccess } from "@/api/response";
import { PointUserCSVGetRequest } from "@/api/analysis/point-user-csv/request";

@Component({
  components: {
    UlBarChart: UlBarChart,
    UlPieChart: UlPieChart
  }
})
export default class AnalysisPointChart extends Mixins(AnalysisBase) {
  @Prop() inputOptions!: PointInputOptions;
  @Prop() data!: AnalysisGraphData;

  @Watch("totalCountOnDrillDowned")
  didUpdateTotalCount(value: number) {
    this.updateTotalCountOnDrillDowned(value);
  }

  @Emit()
  public updateTotalCountOnDrillDowned(value: number) {
    return value;
  }

  // ローディングステータス
  isLoadedPointProfile = false;

  drillDownHierarchy = 0;
  totalCountOnDrillDowned = 0;

  chart: Record<string, any> = {};

  get pointChartData() {
    return this.getBarChartData(this.data);
  }

  get pointChartOption() {
    return this.getBarChartOptions(
      this.data,
      true,
      true,
      this.drillDownPointProfile
    );
  }

  get genderChartData() {
    return this.getPieChartData(PointProfileGet.getGenderData);
  }

  get genderChartOptions() {
    return this.getPieChartOptions(
      PointProfileGet.getGenderData,
      this.fetchPointUserCSVByGender
    );
  }

  get periodChartData() {
    return this.getPieChartData(PointProfileGet.getPeriodData);
  }

  get periodChartOptions() {
    return this.getPieChartOptions(
      PointProfileGet.getPeriodData,
      this.fetchPointUserCSVByPeriod
    );
  }

  get profile1ChartData() {
    return this.getPieChartData(PointProfileGet.getProfile1Data);
  }

  get profile1ChartOptions() {
    return this.getPieChartOptions(
      PointProfileGet.getProfile1Data,
      this.fetchPointUserCSVByProfile1
    );
  }

  get profile2ChartData() {
    return this.getPieChartData(PointProfileGet.getProfile2Data);
  }

  get profile2ChartOptions() {
    return this.getPieChartOptions(
      PointProfileGet.getProfile2Data,
      this.fetchPointUserCSVByProfile2
    );
  }

  get profile3ChartData() {
    return this.getPieChartData(PointProfileGet.getProfile3Data);
  }

  get profile3ChartOptions() {
    return this.getPieChartOptions(
      PointProfileGet.getProfile3Data,
      this.fetchPointUserCSVByProfile3
    );
  }

  get profile4ChartData() {
    return this.getPieChartData(PointProfileGet.getProfile4Data);
  }

  get profile4ChartOptions() {
    return this.getPieChartOptions(
      PointProfileGet.getProfile4Data,
      this.fetchPointUserCSVByProfile4
    );
  }

  get profile5ChartData() {
    return this.getPieChartData(PointProfileGet.getProfile5Data);
  }

  get profile5ChartOptions() {
    return this.getPieChartOptions(
      PointProfileGet.getProfile5Data,
      this.fetchPointUserCSVByProfile5
    );
  }

  /**
   * createdライフサイクルフック
   */
  async created() {}

  /**
   * beforeDestroyライフサイクルフック
   */
  async beforeDestroy() {}

  async fetchPointProfile(
    inputOptions: PointProfileGetRequest
  ): Promise<boolean> {
    this.isLoadedPointProfile = false;
    await Flash.clear();
    await PointProfileGet.get(inputOptions);
    if (!PointProfileGet.isSuccess) {
      await Flash.setErrorNow({
        message: PointProfileGet.getMessage,
        showReloadButton: false
      } as ErrorAlert);
    }
    this.isLoadedPointProfile = true;
    return PointProfileGet.isSuccess;
  }

  onClickBack() {
    this.totalCountOnDrillDowned = 0;
    this.drillDownHierarchy = 0;
    this.totalCountOnDrillDowned = 0;
  }

  async drillDownPointProfile(
    event: MouseEvent | undefined,
    activeElements: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0) ||
      this.isDashboard ||
      this.inputOptions.selectShop == null
    ) {
      return false;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      this.fetchPointUserCSV(0, element._index);
      return;
    }

    const pointProfileGetRequest: PointProfileGetRequest = {
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      viewType: this.inputOptions.viewType,
      categoryType: this.inputOptions.categoryType,
      horizontalAxis: "",
      shopId: Number(this.inputOptions.selectShop.id),
      totalCount: PointGet.getTotalCount
    };

    const element = this.chart.getElementAtEvent(event)[0] as ChartElement;
    pointProfileGetRequest.horizontalAxis = this.data.labelsData[
      element._index
    ];
    await this.fetchPointProfile(pointProfileGetRequest);

    this.data.datasets.forEach(d => {
      const data = d.data;
      if (data != null) {
        this.totalCountOnDrillDowned += Number(data[element._index]);
      }
    });
    this.drillDownHierarchy = 1;
  }

  async fetchPointUserCSVByProfile1(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointUserCSVByProfile(1, event, activeElements);
  }

  async fetchPointUserCSVByProfile2(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointUserCSVByProfile(2, event, activeElements);
  }

  async fetchPointUserCSVByProfile3(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointUserCSVByProfile(3, event, activeElements);
  }

  async fetchPointUserCSVByProfile4(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointUserCSVByProfile(4, event, activeElements);
  }

  async fetchPointUserCSVByProfile5(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointUserCSVByProfile(5, event, activeElements);
  }

  async fetchPointUserCSVByGender(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointUserCSVByProfile(6, event, activeElements);
  }

  async fetchPointUserCSVByPeriod(
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    this.fetchPointUserCSVByProfile(7, event, activeElements);
  }

  async fetchPointUserCSVByProfile(
    graphType: number,
    event?: MouseEvent | undefined,
    activeElements?: Array<{}> | undefined
  ) {
    if (
      !event ||
      !activeElements ||
      (activeElements && activeElements.length <= 0)
    ) {
      return;
    }

    if (event.ctrlKey || event.shiftKey) {
      const element = activeElements[0] as ChartElement;
      await this.fetchPointUserCSV(graphType, element._index);
    }
  }

  async fetchPointUserCSV(graphType: number, elementIndex: number) {
    if (this.inputOptions.selectShop == null) {
      return false;
    }
    const pointUserCSVGetRequest: PointUserCSVGetRequest = {
      shopId: Number(this.inputOptions.selectShop.id),
      startDate: this.inputOptions.startDate,
      endDate: this.inputOptions.endDate,
      viewType: this.inputOptions.viewType,
      horizontalAxis:
        graphType == 0
          ? this.data.labelsData[elementIndex]
          : PointProfileGet.getResult.horizontalAxis,
      graphType: graphType
    };

    if (graphType == 1) {
      pointUserCSVGetRequest.profileData =
        PointProfileGet.getProfile1Data.labels[elementIndex];
    } else if (graphType == 2) {
      pointUserCSVGetRequest.profileData =
        PointProfileGet.getProfile2Data.labels[elementIndex];
    } else if (graphType == 3) {
      pointUserCSVGetRequest.profileData =
        PointProfileGet.getProfile3Data.labels[elementIndex];
    } else if (graphType == 4) {
      pointUserCSVGetRequest.profileData =
        PointProfileGet.getProfile4Data.labels[elementIndex];
    } else if (graphType == 5) {
      pointUserCSVGetRequest.profileData =
        PointProfileGet.getProfile5Data.labels[elementIndex];
    } else if (graphType == 6) {
      pointUserCSVGetRequest.profileData =
        PointProfileGet.getGenderData.labels[elementIndex];
    } else if (graphType == 7) {
      pointUserCSVGetRequest.profileData =
        PointProfileGet.getPeriodData.labels[elementIndex];
    }

    const pointUserCSVGetResponse = await PointUserCSV.get(
      pointUserCSVGetRequest
    );
    if (!isSuccess(pointUserCSVGetResponse)) {
      await Flash.setErrorNow({
        message: pointUserCSVGetResponse.message,
        showReloadButton: true
      } as ErrorAlert);
    }

    this.saveCSV(
      pointUserCSVGetResponse.results.csvString,
      pointUserCSVGetResponse.results.fileName
    );
  }

  chartRendered(chart: Record<string, any>) {
    this.chart = chart;
  }
}
