import service from "@/api/service";
import { PointGetRequest } from "@/api/analysis/point/request";
import { PointGetResponse } from "@/api/analysis/point/response";

/**
 * ポイント付与数・利用金額情報取得APIをコールします。
 *
 * @param getRequest ポイント付与数・利用金額情報取得APIのリクエストボディ
 * @return PointGetResponse
 */
export async function get(getRequest: PointGetRequest) {
  const response = await service.post("/analysis-point", getRequest);
  return response.data as PointGetResponse;
}
